import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"


import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import receiveDeliveries from "../../../recoil-state/receiveDeliveries"
import location from "../../../recoil-state/location"
import deliveryDetailsView from "../../../recoil-state/deliveryDetailsView"
import availableDeliveries from "../../../recoil-state/deliveries"

import { navigate } from "gatsby"

import { getUser, logOut } from "../../../services/auth"

import { addRiderToIdle, removeRiderToIdle } from '../../../api/spark-express/idleRider'

import { pingToReactNativeWebview } from "../../../services/react native webview"

let useStyles = makeStyles({
  deliveriesToggle: {
    backgroundColor: "white",
    position: "fixed",
    top: "0.5em",
    width: "100%",
    height: "3em",
    display: "flex",
    fontFamily: "visby",
    zIndex: 99,
  },
  logout: {
    textAlign: "right",
    width: "50%",
    fontSize: "1em",
    display: "flex",
    justifyContent: "flex-end",
  },
})

const DeliveriesToggle = () => {
  let styles = useStyles()
  let riderLocation = useRecoilValue(location)
  let setDeliveryDetailsView = useSetRecoilState(deliveryDetailsView)

  let setAvailableDeliveries = useSetRecoilState(availableDeliveries)

  let [isReceivingDeliveries, toggleReceiveDeliveries] =
    useRecoilState(receiveDeliveries)

  const handleChange = () => {
    toggleReceiveDeliveries(!isReceivingDeliveries)
  }

  const logout = () => {
    logOut()
    pingToReactNativeWebview("LOGOUT")
    setDeliveryDetailsView({})
    toggleReceiveDeliveries(false)
    setAvailableDeliveries({})
    removeRiderToIdle(getUser().userId)
    navigate("/")
  }

  useEffect(() => {
    let mounted = true

    if (mounted) {
      if (getUser().userId.length > 0) {
        //update on firestore
        if (isReceivingDeliveries) {
          addRiderToIdle(
            getUser().userId, [
            riderLocation.lng,
            riderLocation.lat]
          )
        } else {
          removeRiderToIdle(getUser().userId)
          setDeliveryDetailsView({})
        }
      } else {
        alert('Your account needs to be logged out and in.')
      }
    }

    return () => {
      mounted = false
    }
  }, [isReceivingDeliveries])

  return (
    <div className={styles.deliveriesToggle}>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={isReceivingDeliveries}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Receive Deliveries"
        />

        {isReceivingDeliveries}
      </div>
      <div className={styles.logout} onClick={logout}>
        <div style={{ marginTop: "5%" }}>Logout</div>
        <ExitToAppIcon style={{ marginTop: "5%" }} />
      </div>
    </div>
  )
}

export default DeliveriesToggle
