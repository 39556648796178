import React, { useState, useEffect } from "react"
import riderLocation from "../../../../recoil-state/location"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { getUser } from "../../../../services/auth"
import PendingDeliveryCardView from "./view"
import firebase from "../../../../services/firebase"
import locationBidDialog from "../../../../recoil-state/locationBidDialog"

import calculateDistance from "../../../../utils/calculateDistance"

const PendingDeliveryCard = ({ details }) => {
  const riderLoc = useRecoilValue(riderLocation)
  const [bidSubmitted, setBidSubmitted] = useState(false)
  const setAlert = useSetRecoilState(locationBidDialog)

  useEffect(() => {
    console.log(details?.bids)

    for (let bidId in details?.bids) {
      let bid = details?.bids[bidId]
      console.log(bid.user)
      if (bid.user === getUser().userId) setBidSubmitted(true)
    }
  }, [])
  async function onBidLocation(deliveryLocation) {
    console.log('bid success')
    let { userId } = getUser()

    let reference = firebase.database.ref(`available_deliveries/${details.orderId}/bids`).push()
    reference.set({
      user: userId, 
      distance: calculateDistance(deliveryLocation.lat, deliveryLocation.lng, riderLoc.lat, riderLoc.lng),
      location: riderLoc
    })
    setBidSubmitted(true)
    setAlert(true)
  }
  return (
    <PendingDeliveryCardView
      bidSubmitted={bidSubmitted}
      details={details}
      onBidLocation={onBidLocation}
    />
  )
}

export default PendingDeliveryCard
